import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddItemFormDialog from "../components/FormDialog/AddItemFormDialog";
import AddOrderFormDialog from "../components/FormDialog/AddOrderFormDialog";
import OrderItemFormDialog from "../components/FormDialog/OrderItemFormDialog";
import CollapseTable from "../components/Table/CollapseTable";
import api from "../components/api";
import moment from "moment";
import { Order, OrderOrderItem, User } from "../api";
import AddOrderOrderItemFormDialog from "../components/FormDialog/AddOrderOrderItemFormDialog";
import EditOrderFormDialog from "../components/FormDialog/EditOrderFormDialog";
import DeleteFormDialog from "../components/FormDialog/DeleteFormDialog";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReorderIcon from "@mui/icons-material/Reorder";
import { config } from "../components/api";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import GenericTable from "../components/Table/Table";
import Calendar from "../components/Calendar/Calendar";
import { Dayjs } from "dayjs";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CalendarList from "../components/Calendar/CalendarList";

const OrderPage: React.FC = () => {
  const [clientName, setClientName] = React.useState<string>("");
  const { user_id } = useParams<{ user_id: string }>();
  const location = useLocation();
  const isAllOrdersPage = location.pathname === "/order";

  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openCreateOrderDialog, setOpenCreateOrderDialog] =
    React.useState(false);
  const [openOrderItemDialog, setOpenOrderItemDialog] = React.useState(false);
  const [orders, setOrders] = React.useState<any[]>([]);
  const [openEditOrderDialog, setOpenEditOrderDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDeleteOrderOrderItemDialog, setOpenDeleteOrderOrderItemDialog] =
    React.useState(false);
  const [openAddOrderOrderItemDialog, setOpenAddOrderOrderItemDialog] =
    React.useState(false);
  const [orderToEdit, setOrderToEdit] = React.useState<Order | null>(null);
  const [orderToDelete, setOrderToDelete] = React.useState<Order | null>(null);
  const [orderOrderItemToDelete, setOrderOrderItemToDelete] =
    React.useState<OrderOrderItem | null>(null);
  const [orderOrderItemToAdd, setOrderOrderItemToAdd] =
    React.useState<any>(null);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [highlightedDays, setHighlightedDays] = React.useState<Dayjs[]>([]);
  const [isCalendarMode, setIsCalendarMode] = React.useState(true);
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(true);

  const fetchOrdersByDate = async (date: Dayjs) => {
    try {
      const order_date = date.format("YYYY-MM-DD"); 
      let ordersData = []; 
      
      if (config.role === "staff") { 
        const [orderResponse, orderStaffResponse] = await Promise.all([ 
          api.orders.getOrdersOrdersGet(order_date), 
          api.orderStaffs.getUsersOrderStaffsUsersOrderStaffsGet() 
        ]); 
        
        const ordersContents = orderResponse.data.contents; 
        const orderStaffContents = orderStaffResponse.data.contents; 

        const commonOrders = ordersContents.filter(order => 
          orderStaffContents.some(orderStaff => order.order_id === orderStaff.order_id) 
        ); 
        
        ordersData = commonOrders.map((order: any) => ({ 
          ...order, 
          date: moment(order.date).format("YYYY/MM/DD HH:mm:ss"), 
          name: `${order.user.first_name} ${order.user.last_name}`, 
          status: order.order_status?.status || "Unknown", 
          order_order_items: order.order_order_items?.map((item: any) => ({ 
            ...item, 
            description: item.description || "No description", 
            price: item.price || 0, 
          })) || [], 
        })); 
      } else { 
        const response = await api.orders.getOrdersOrdersGet(order_date); 
        ordersData = response.data.contents.map((order: any) => ({ 
          ...order, 
          date: moment(order.date).format("YYYY/MM/DD HH:mm:ss"), 
          name: `${order.user.first_name} ${order.user.last_name}`, 
          status: order.order_status?.status || "Unknown", 
          order_order_items: order.order_order_items?.map((item: any) => ({ 
            ...item, 
            description: item.description || "No description", 
            price: item.price || 0, 
          })) || [], 
        })); 
      }
      setOrders(ordersData);
    } catch (error) {
      console.error("Error fetching orders by date:", error);
    }
  };

  const hasDateParam = new URLSearchParams(location.search).has("date");

  React.useEffect(() => {
    const fetchOrders = async () => {
      try {
        let response;
        let orders: any[] = [];
        let orderStaff: any[] = [];

        if (config.role === "staff") {
          const orderStaffResponse =
            await api.orderStaffs.getUsersOrderStaffsUsersOrderStaffsGet();
          const orderStaffContents = orderStaffResponse.data.contents;
    
          orderStaff = orderStaffContents
            .filter((orderStaff) => Object.keys(orderStaff.order).length > 0)
            .map((orderStaff: any) => ({
              status: orderStaff.order.status,
              date: orderStaff.order.date,
              type: orderStaff.order.type,
              user_id: orderStaff.user_id,
              order_id: orderStaff.order_id,
              user: {
                first_name: orderStaff.user.first_name,
                last_name: orderStaff.user.last_name,
              },
            }));

          interface OrderStatus { 
            complete_date?: string; 
            description?: string; 
            status_rank?: number; 
            status_category?: string; 
            status?: string; 
            deadline?: string | null; 
            receiver?: any[]; 
          } 
          
          interface OrderUser { 
            first_name?: string; 
            last_name?: string; 
          } 

          interface OrderData { 
            date: string; 
            type?: string; 
            user_id: string; 
            self_link: string; 
            kind: string; 
            order_id: string; 
            user: OrderUser; 
            order_status?: OrderStatus; 
          }

          for (const orderStaff of orderStaffContents) { 
            if (Object.keys(orderStaff.order).length > 0 && orderStaff.order_id) { 
              const orderResponse = await api.orders.getOrderOrdersOrderIdGet(orderStaff.order_id); 
              const orderData: OrderData = { 
                ...orderResponse.data,
                type: orderResponse.data.type || "",
                order_status: orderResponse.data.order_status || {}
              };

              const orderStatus = orderData.order_status || {}; 
              const orderUser = orderData.user || {}; 
              
              orders.push({ 
                order_status: { 
                  complete_date: orderStatus.complete_date, 
                  description: orderStatus.description, 
                  status_rank: orderStatus.status_rank, 
                  status_category: orderStatus.status_category, 
                  status: orderStatus.status, 
                  deadline: orderStatus.deadline, 
                  receiver: orderStatus.receiver, 
                }, 
                status: orderData.order_status?.status || "Unknown",
                date: moment(orderData.date).format("YYYY/MM/DD HH:mm:ss"), 
                type: orderData.type, 
                user_id: orderData.user_id, 
                order_id: orderData.order_id, 
                name: `${orderUser.first_name} ${orderUser.last_name}`,
                user: { 
                  first_name: orderUser.first_name, 
                  last_name: orderUser.last_name, 
                }, 
              }); 
            }
          }
          setOrders(orders)
        } else {
          if(user_id){
            response = user_id
              ? await api.orders.getUserUserIdOrdersUsersUserIdOrdersGet(user_id)
              : await api.orders.getOrdersOrdersGet();
          } else{
            response = await api.orders.getOrdersOrdersGet();
          }
        
          const ordersData = response.data.contents.map((order: any) => {
            const orderDate = moment(order.date).format("YYYY/MM/DD HH:mm:ss");
            const status = order.order_status?.status || "Unknown";
              
            const orderItems = order.order_order_items?.map((item: any) => ({
                ...item,
              description: item.description || "No description",
              price: item.price || 0,
              order_item_id: item.order_item_id,
            })) || [];

            return {
              ...order,
              date: orderDate,
              name: `${order.user.first_name} ${order.user.last_name}`, 
              status: status,
              order_order_items: orderItems,
            };
          });
          setOrders(ordersData);

          if (ordersData.length > 0) {
            const { first_name, last_name } = ordersData[0].user;
            setClientName(`${first_name} ${last_name}`);
          }
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    if (!hasDateParam) {
      fetchOrders();
    } else if (selectedDate) {
      fetchOrdersByDate(selectedDate);
    } else {
      fetchOrders();
    }

  }, [user_id, selectedDate, hasDateParam]);
  
  React.useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userResponse = await api.users.getUserUserGet();
        setSelectedUser(userResponse.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error,
        );
      }
    };

    fetchCurrentUser();
  }, []);

  const handleOpenEditOrderDialog = (row: any) => {
    setOrderToEdit(row);
    setOpenEditOrderDialog(true);
  };

  const handleCloseEditOrderDialog = () => {
    setOrderToEdit(null);
    setOpenEditOrderDialog(false);
  };

  const handleOpenDeleteDialog = (row: any) => {
    setOrderToDelete(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOrderToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteOrderOrderItemDialog = (
    row: any,
    orderOrderItem: OrderOrderItem,
  ) => {
    setOrderToDelete(row);
    setOrderOrderItemToDelete(orderOrderItem);
    setOpenDeleteOrderOrderItemDialog(true);
  };

  const handleCloseDeleteOrderOrderItemDialog = () => {
    setOrderOrderItemToDelete(null);
    setOpenDeleteOrderOrderItemDialog(false);
  };

  const handleOpenAddOrderOrderItemDialog = (row: any) => {
    setOrderOrderItemToAdd(row);
    setOpenAddOrderOrderItemDialog(true);
  };

  const handleCloseAddOrderOrderItemDialog = () =>
    setOpenAddOrderOrderItemDialog(false);

  const handleSaveEditOrder = (updatedOrder: Order) => {
    if (handleEditOrder) handleEditOrder(updatedOrder);
    handleCloseEditOrderDialog();
  };

  const handleDeleteOrderClick = () => {
    if (orderToDelete && handleDeleteOrder) {
      handleDeleteOrder(orderToDelete.order_id);
      handleCloseDeleteDialog();
    }
  };

  const handleDeleteOrderOrderItemClick = () => {
    if (orderOrderItemToDelete) {
      handleCloseDeleteOrderOrderItemDialog();
    }
  };

  const handleAddOrderOrderItem = (updatedOrder: Order) => {
    if (handleEditOrder) handleEditOrder(updatedOrder);
    handleCloseAddOrderOrderItemDialog();
  };

  const handleCreateItemClick = () => setOpenCreateDialog(true);
  const handleCreateOrderClick = () => setOpenCreateOrderDialog(true);
  const handleOrderItemClick = () => setOpenOrderItemDialog(true);
  const handleCloseCreateDialog = () => setOpenCreateDialog(false);
  const handleCloseCreateOrderDialog = () => setOpenCreateOrderDialog(false);
  const handleCloseOrderItemDialog = () => setOpenOrderItemDialog(false);

  const handleAddOrder = (newOrder: any) => setOrders([newOrder, ...orders]);

  const handleEditOrder = (updatedOrder: any) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) =>
        order.order_id === updatedOrder.order_id ? updatedOrder : order,
      );
      updatedOrders.sort((a, b) =>
        a.user_id === updatedOrder.order_id
          ? -1
          : b.user_id === updatedOrder.order_id
            ? 1
            : 0,
      );
      return updatedOrders;
    });
  };

  const handleDeleteOrder = (deletedOrderId: string) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.filter(
        (order) => order.order_id !== deletedOrderId,
      );
      return updatedOrders;
    });
  };

  const columnConfig = [
    { id: "expand", label: "", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 170 },
    { id: "type", label: "Type", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const detailColumnsAdmin = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const detailColumnsClient = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
  ];

  const getRows = async () => {
    let orders: any[] = [];
    let allOrderItems: any[] = [];
    let orderStaff: any[] = [];

    if (config.role === "staff") {
      const orderStaffResponse =
        await api.orderStaffs.getUsersOrderStaffsUsersOrderStaffsGet();
      const orderStaffContents = orderStaffResponse.data.contents;

      orderStaff = orderStaffContents
        .filter((orderStaff) => Object.keys(orderStaff.order).length > 0)
        .map((orderStaff: any) => ({
          status: orderStaff.order.status,
          date: orderStaff.order.date,
          type: orderStaff.order.type,
          user_id: orderStaff.user_id,
          order_id: orderStaff.order_id,
          user: {
            first_name: orderStaff.user.first_name,
            last_name: orderStaff.user.last_name,
          },
        }));

      interface OrderData { 
        date: string; 
        type?: string; 
        user_id: string; 
        self_link: string; 
        kind: string; 
        order_id: string; 
        user: OrderUser; 
        order_status?: OrderStatus; 
      }

      interface OrderStatus { 
        complete_date?: string; 
        description?: string; 
        status_rank?: number; 
        status_category?: string; 
        status?: string; 
        deadline?: string | null; 
        receiver?: any[]; 
      } 
      
      interface OrderUser { 
        first_name?: string; 
        last_name?: string; 
      }

      for (const orderStaff of orderStaffContents) { 
        if (Object.keys(orderStaff.order).length > 0 && orderStaff.order_id) { 
          const orderResponse = await api.orders.getOrderOrdersOrderIdGet(orderStaff.order_id); 
          const orderData: OrderData = { 
            ...orderResponse.data,
            type: orderResponse.data.type || "",
            order_status: orderResponse.data.order_status || {}
          };

          const orderStatus = orderData.order_status || {}; 
          const orderUser = orderData.user || {}; 

          orders.push({
            status: orderStatus.status,
            date: orderData.date,
            type: orderData.type,
            user_id: orderData.user_id,
            order_id: orderData.order_id,
            user: {
              first_name: orderUser.first_name,
              last_name: orderUser.last_name,
            },
          });
        }
      }
    } else {
        if (user_id) {
          const response = await api.orders.getUserUserIdOrdersUsersUserIdOrdersGet(user_id);
          orders = response.data.contents;
        } else {
          const response = await api.orders.getOrdersOrdersGet();
          orders = response.data.contents;
        }

      const orderOrderItemResponse =
        await api.orderOrderItems.getOrderOrderItemsOrderOrderItemsGet();
      allOrderItems = orderOrderItemResponse.data.contents;
    }

    return orders.map((order: any) => {
      const orderDate = moment(order.date).format("YYYY/MM/DD HH:mm:ss");

      const orderItems =
        config.role !== "staff"
          ? allOrderItems.filter((item) => item.order_id === order.order_id)
          : [];

      return {
        ...order,
        name: `${order.user.first_name} ${order.user.last_name}`,
        date: orderDate,
        order_order_items: orderItems,
      };
    });
  };

  const navigate = useNavigate();

  const handleOrderAnswerClick = (row: any) => {
    navigate(`/order_answer/${row.order_id}`, { state: { row } });
  };

  const handleOrderInfoClick = (row: any) => {
    navigate(`/order_info/${row.order_id}`, { state: { row } });
  };

  const getActionsButton = (row: any) => {
    return (
      <>
        {(config.role === "admin" || config.role === "manager") && (
          <>
            <IconButton
              aria-label="edit"
              size="small"
              color="primary"
              onClick={() => handleOpenEditOrderDialog(row)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleOpenDeleteDialog(row)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="info"
              size="small"
              onClick={() => handleOrderInfoClick(row)}
            >
              <InfoIcon />
            </IconButton>
          </>
        )}
        <IconButton
          aria-label="info"
          size="small"
          onClick={() => handleOrderAnswerClick(row)}
        >
          <QuestionAnswerIcon />
        </IconButton>
      </>
    );
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleToggleView = () => {
    setIsCalendarMode((prev) => !prev);
  };

  const toggleCalendar = () => {
    setIsCalendarVisible((prev) => !prev);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">{user_id ? `${clientName}'s Orders` : "Order"}</Typography>
        {(config.role === "admin" || config.role === "manager") && (
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOrderItemClick}
            >
              Order Items
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateItemClick}
            >
              Add Item
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateOrderClick}
            >
              Add Order
            </Button>
            {!user_id && (
              <Button
                variant="contained"
                color="primary"
                onClick={toggleCalendar}
              >
                {
                  isCalendarVisible ? "Hide " : "Show "
                }
                Calendar
              </Button>
            )}
          </Box>
        )}
      </Box>

      <Grid container spacing={2}> 
        <Grid item xs={user_id ? 12 : 8}> 
          {config.role === "staff" ? ( 
            <GenericTable 
            columnsConfig={columnConfig} 
            tableType={"order"} 
            rows={orders} 
            getRows={getRows}
            setRows={setOrders} 
            getActionsButton={getActionsButton} 
          /> 
        ) : ( 
          <CollapseTable 
            columnsConfig={columnConfig} 
            detailColumns={ 
              config.role === "client" ? detailColumnsClient : detailColumnsAdmin 
            } 
            tableType="order" 
            rows={orders} 
            getRows={getRows} 
            setRows={setOrders} 
            handleOpenAddOrderOrderItemDialog={handleOpenAddOrderOrderItemDialog} 
            handleOpenDeleteOrderOrderItemDialog={ 
              handleOpenDeleteOrderOrderItemDialog 
            } 
            getActionsButton={getActionsButton} 
          /> 
        )} 
      </Grid> 

      {!user_id && isCalendarVisible && (
        <Grid item xs={4}> 
          <Card> 
            <CardContent> 
              <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center" 
              > 
                <Typography variant="h6">Calendar View</Typography> 
                <IconButton onClick={handleToggleView}> 
                  {isCalendarMode ? <ReorderIcon /> : <CalendarMonthIcon />} 
                </IconButton> 
              </Box> 
              
              {isCalendarMode ? ( 
                <Calendar 
                  user={selectedUser} 
                  handleDateChange={handleDateChange} 
                  highlightedDays={highlightedDays} 
                  setHighlightedDays={setHighlightedDays} 
                /> 
              ) : ( 
                <CalendarList user={selectedUser} /> 
              )} 
            </CardContent> 
          </Card> 
        </Grid> 
      )}
    </Grid>

      {(config.role === "admin" || config.role === "manager") && (
        <>
          <AddItemFormDialog
            open={openCreateDialog}
            handleClose={handleCloseCreateDialog}
          />
          <OrderItemFormDialog
            open={openOrderItemDialog}
            handleClose={handleCloseOrderItemDialog}
          />
          <AddOrderFormDialog
            open={openCreateOrderDialog}
            handleClose={handleCloseCreateOrderDialog}
            addOrder={handleAddOrder}
          />

          {orderOrderItemToAdd && (
            <AddOrderOrderItemFormDialog
              open={openAddOrderOrderItemDialog}
              handleClose={handleCloseAddOrderOrderItemDialog}
              order={orderOrderItemToAdd}
              addOrderOrderItems={handleAddOrderOrderItem}
            />
          )}
          {orderToEdit && (
            <EditOrderFormDialog
              open={openEditOrderDialog}
              handleClose={handleCloseEditOrderDialog}
              row={orderToEdit}
              onSave={handleSaveEditOrder}
            />
          )}
          {orderToDelete && (
            <DeleteFormDialog
              open={openDeleteDialog}
              handleClose={handleCloseDeleteDialog}
              order={orderToDelete}
              onDelete={handleDeleteOrderClick}
            />
          )}

          {orderToDelete && orderOrderItemToDelete && (
            <DeleteFormDialog
              open={openDeleteOrderOrderItemDialog}
              handleClose={handleCloseDeleteOrderOrderItemDialog}
              order={orderToDelete}
              order_order_item={orderOrderItemToDelete}
              onDelete={handleDeleteOrderOrderItemClick}
              onEdit={handleSaveEditOrder}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default OrderPage;
