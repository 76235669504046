import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AxiosResponse } from "axios";
import api from "../api";
import {
  OrderType,
  OrderCreate,
  OrderItem,
  User,
  OrderOrderItem,
  OrderOrderItemCreate,
  StatusField,
  OrderStatusCreate,
  Status,
} from "../../api/api";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment/moment";

interface AddOrderFormDialogProps {
  open: boolean;
  handleClose: () => void;
  addOrder: (order: any) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  selectedItems: readonly string[],
  theme: any,
) => {
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const AddOrderFormDialog: React.FC<AddOrderFormDialogProps> = ({
  open,
  handleClose,
  addOrder,
}) => {
  const theme = useTheme();

  const [orderCreate, setOrderCreate] = React.useState<OrderCreate>({
    date: dayjs().toISOString(),
    user_id: "",
  });

  const [orderItems, setOrderItems] = React.useState<OrderItem[]>([]);
  const [selectedOrderItems, setSelectedOrderItems] = React.useState<string[]>(
    [],
  );
  const [users, setUsers] = React.useState<User[]>([]);
  const [statuses, setStatuses] = React.useState<Status[]>([]);
  const [selectedStatusId, setSelectedStatusId] = React.useState<string>("");

  React.useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const response = await api.orderItems.getOrderItemsOrderItemsGet();
        const data = response.data.contents;
        setOrderItems(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des order items :",
          error,
        );
      }
    };
    fetchOrderItems();

    const fetchUsers = async () => {
      try {
        const response =
          await api.users.getUsersRoleUsersRoleTargetRoleGet("client");
        const data = response.data.contents;
        setUsers(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error,
        );
      }
    };
    fetchUsers();

    const fetchStatuses = async () => {
      try {
        const response =
          await api.status.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
            "order",
          );
        const data = response.data.contents;
        setStatuses(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des statuts :", error);
      }
    };
    fetchStatuses();
  }, []);

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    name: string,
  ) => {
    const { value } = event.target;
    if (name === "status") {
      setSelectedStatusId(value);
    } else {
      setOrderCreate((prevState: OrderCreate) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderItems(typeof value === "string" ? value.split(",") : value);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setOrderCreate((prevState: OrderCreate) => ({
      ...prevState,
      date: date ? date.toISOString() : "",
    }));
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const orderResponse: AxiosResponse<any> =
        await api.orders.postOrderOrdersPost(orderCreate);
      const orderId = orderResponse.data.order_id;

      let allOrderOrderItem: OrderOrderItem[] = [];

      for (const orderItemId of selectedOrderItems) {
        const orderOrderItemCreate: OrderOrderItemCreate = {
          order_id: orderId,
          order_item_id: orderItemId,
        };

        const orderOrderItemResponse =
          await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(
            orderOrderItemCreate,
          );
        const orderOrderItems = orderOrderItemResponse.data;

        allOrderOrderItem.push(orderOrderItems);
      }

      const orderDate = moment(orderResponse.data.date).format(
        "YYYY/MM/DD HH:mm:ss",
      );

      const data = {
        ...orderResponse.data,
        name:
          orderResponse.data.user.first_name +
          " " +
          orderResponse.data.user.last_name,
        date: orderDate,
        order_order_items: allOrderOrderItem,
      };

      addOrder(data);

      const orderStatusCreate: OrderStatusCreate = {
        complete_date: new Date().toISOString(),
        order_id: orderId,
        status_id: selectedStatusId,
        description: null,
      };

      await api.orderStatus.postOrderStatusOrderStatusesPost(orderStatusCreate);

      handleClose();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Add Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the necessary information to add an order:
        </DialogContentText>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            value={orderCreate.user_id}
            onChange={(event) => handleSelectChange(event, "user_id")}
          >
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-type-select-label">Order Type</InputLabel>
          <Select
            labelId="order-type-select-label"
            id="order-type-select"
            value={orderCreate.type || ""}
            onChange={(event) => handleSelectChange(event, "type")}
          >
            {Object.values(OrderType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-status-select-label">Order Status</InputLabel>
          <Select
            labelId="order-status-select-label"
            id="order-status-select"
            value={selectedStatusId}
            onChange={(event) => handleSelectChange(event, "status")}
          >
            {statuses.map((status) => (
              <MenuItem key={status.status_id} value={status.status_id}>
                {status.status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-items-select-label">Order Items</InputLabel>
          <Select
            labelId="order-items-select-label"
            id="order-items-select"
            multiple
            value={selectedOrderItems}
            onChange={handleMultiSelectChange}
            input={
              <OutlinedInput id="select-multiple-chip" label="Order Items" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      orderItems.find((item) => item.order_item_id === value)
                        ?.description || value
                    }
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {orderItems.map((item) => (
              <MenuItem
                key={item.order_item_id}
                value={item.order_item_id}
                style={getStyles(item.description, selectedOrderItems, theme)}
              >
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box mt={1}>
            <DatePicker
              label="Order Date"
              onChange={(date) => handleDateChange(date)}
              value={orderCreate.date ? dayjs(orderCreate.date) : null}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderFormDialog;
