import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AxiosResponse } from "axios";
import api from "../api";
import { QuestionCreate } from "../../api/api";
import { Autocomplete } from "@mui/material";
import { useEffect } from "react";

interface AddQuestionFormDialogProps {
  open: boolean;
  handleClose: () => void;
  addQuestion: (question: any) => void;
}

const AddQuestionFormDialog: React.FC<AddQuestionFormDialogProps> = ({
  open,
  handleClose,
  addQuestion,
}) => {
  const [types, setTypes] = React.useState<string[]>([]);
  const [questionCreate, setQuestionCreate] = React.useState<QuestionCreate>({
    text: "",
    type: "" || null,
    rank: 0,
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>,
  ) => {
    if ("name" in event.target) {
      const { name, value } = event.target as HTMLInputElement;
      setQuestionCreate((prevState) => ({
        ...prevState,
        [name]: name === "rank" && value === "" ? null : value,
      }));
    } else {
      console.warn("Unexpected target:", event.target);
    }
  };
  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response: AxiosResponse<any> =
        await api.questions.postQuestionQuestionsPost(questionCreate);

      addQuestion(response.data);
      handleClose();
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response: AxiosResponse<string[]> =
          await api.questions.getQuestionTypesQuestionsTypesGet();
        setTypes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des types :", error);
      } finally {
      }
    };

    fetchTypes();
  }, []);

  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    value: string | null,
  ) => {
    setQuestionCreate({
      ...questionCreate,
      type: value ?? "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Add Question</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the necessary information to add an question:
        </DialogContentText>

        <TextField
          margin="dense"
          id="text"
          name="text"
          label="Text"
          fullWidth
          variant="standard"
          required
          value={questionCreate.text}
          onChange={handleInputChange}
        />

        <Autocomplete
          freeSolo
          value={questionCreate.type ?? ""}
          onChange={handleAutocompleteChange}
          disableClearable
          options={types}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="type"
              name="type"
              label="Type"
              fullWidth
              variant="standard"
              required
              onChange={handleInputChange}
            />
          )}
        />

        <TextField
          margin="dense"
          id="rank"
          name="rank"
          label="Rank"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          fullWidth
          variant="standard"
          value={questionCreate.rank}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddQuestionFormDialog;
