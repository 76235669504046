import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import api from "../components/api";
import { NotificationRule } from "../api";
import AddNotificationRuleFormDialog from "../components/FormDialog/AddNotificationRule";
import GenericTable from "../components/Table/Table";

const columnConfig = [
  { id: "user_role", label: "Role", minWidth: 100 },
  { id: "user_profession", label: "Profession", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "inform_day", label: "Inform Day", minWidth: 100 },
];

const NotificationRules: React.FC = () => {
  const [notificationRules, setNotificationRules] = useState<
    NotificationRule[]
  >([]);
  const [openAddRuleDialog, setOpenAddRuleDialog] = useState<boolean>(false);

  const getNotificationRules = async () => {
    const resp =
      await api.notificationRules.getNotificationRulesNotificationRulesGet();
    return resp.data.contents.map((rule: NotificationRule) => {
      return {
        id: rule.notification_rule_id,
        user_role: rule.user_role,
        user_profession: rule.user_professions.join(", "),
        status: rule.status_id,
        inform_day: rule.inform_day,
      };
    });
  };

  const getActionsButton = (row: NotificationRule) => {
    return <></>;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">Notification Rules</Typography>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenAddRuleDialog(true)}
          >
            Add Notification Rule
          </Button>
        </Box>
      </Box>
      <Box>
        <GenericTable
          columnsConfig={columnConfig}
          tableType="notification_rule"
          rows={notificationRules}
          getRows={getNotificationRules}
          setRows={setNotificationRules}
          getActionsButton={getActionsButton}
        />
      </Box>
      <AddNotificationRuleFormDialog
        open={openAddRuleDialog}
        handleClose={() => setOpenAddRuleDialog(false)}
        setNotificationRules={setNotificationRules}
      />
    </Box>
  );
};

export default NotificationRules;
