import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import api, { config } from "../components/api";
import { OrderStaff, OrderOrderItem, User } from "../api";
import AddOrderStaffDialog from "../components/FormDialog/AddOrderStaffDialog";
import DeleteFormDialog from "../components/FormDialog/DeleteFormDialog";
import AddOrderOrderItemFormDialog from "../components/FormDialog/AddOrderOrderItemFormDialog";
import EditFormDialog from "../components/FormDialog/EditFormDialog";
import EditOrderFormDialog from "../components/FormDialog/EditOrderFormDialog";
import GenericTable from "../components/Table/Table";
import Stepper from "../components/Stepper/Stepper";

const OrderInfo: React.FC = () => {
  const { order_id } = useParams<{ order_id?: string }>();
  const [orderStaffs, setOrderStaffs] = useState<any[]>([]);
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [customerDetails, setCustomerDetails] = useState<any | null>(null);
  const [openDeleteOrderStaffDialog, setOpenDeleteOrderStaffDialog] =
    useState(false);
  const [openAddOrderStaffDialog, setOpenAddOrderStaffDialog] = useState(false);
  const [openAddOrderOrderItemDialog, setOpenAddOrderOrderItemDialog] =
    useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditOrderDialog, setOpenEditOrderDialog] = useState(false);
  const [orderStaffToDelete, setOrderStaffToDelete] =
    useState<OrderStaff | null>(null);
  const [orderToAddOrderItem, setOrderToAddOrderItem] = useState<any>(null);
  const [orderOrderItemToDelete, setOrderOrderItemToDelete] =
    useState<any>(null);
  const [openDeleteOrderOrderItemDialog, setOpenDeleteOrderOrderItemDialog] =
    useState(false);

  const staffColumns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "profession", label: "Profession", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const orderItemColumns = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  useEffect(() => {
    if (order_id) {
      const fetchOrderStaffs = async () => {
        try {
          const response =
            await api.orderStaffs.getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
              order_id,
            );
          const staffs = response.data.contents.map((order_staff: any) => ({
            order_staff_id: order_staff.order_staff_id,
            name: `${order_staff.user.first_name} ${order_staff.user.last_name}`,
            profession: order_staff.user.profession,
          }));
          setOrderStaffs(staffs);
        } catch (err) {
          console.error(err);
        }
      };

      const fetchOrderItems = async () => {
        try {
          const response =
            await api.orderOrderItems.getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
              order_id,
            );
          const items = response.data.contents.map((item: any) => ({
            order_order_item_id: item.order_order_item_id,
            description: item.order_item.description,
            price: item.order_item.price,
          }));
          setOrderItems(items);
        } catch (err) {
          console.error(err);
        }
      };

      const fetchOrderDetails = async () => {
        try {
          const response = await api.orders.getOrderOrdersOrderIdGet(order_id);
          const order = response.data;
          setOrderDetails(order);

          const user_response = await api.users.getUserUserIdUserUserIdGet(
            order.user_id,
          );
          const user = user_response.data;
          setCustomerDetails(user);
        } catch (err) {
          console.error(err);
        }
      };

      fetchOrderStaffs();
      fetchOrderItems();
      fetchOrderDetails();
    }
  }, [order_id]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/order/`);
  };

  const handleOpenDeleteOrderStaffDialog = (orderStaff: any) => {
    setOrderStaffToDelete(orderStaff);
    setOpenDeleteOrderStaffDialog(true);
  };

  const handleCloseDeleteOrderStaffDialog = () => {
    setOrderStaffToDelete(null);
    setOpenDeleteOrderStaffDialog(false);
  };

  const handleOpenAddOrderStaffDialog = () => {
    setOpenAddOrderStaffDialog(true);
  };

  const handleCloseAddOrderStaffDialog = () =>
    setOpenAddOrderStaffDialog(false);

  const handleOpenAddOrderOrderItemDialog = () => {
    setOrderToAddOrderItem(order_id);
    setOpenAddOrderOrderItemDialog(true);
  };

  const handleCloseAddOrderOrderItemDialog = () =>
    setOpenAddOrderOrderItemDialog(false);

  const handleOpenEditDialog = () => {
    if (customerDetails) {
      setOpenEditDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEditUser = async (editedUser: any) => {
    setCustomerDetails(editedUser);
  };

  const handleSaveEditOrder = async (editedOrder: any) => {
    setOrderDetails(editedOrder);
  };

  const handleSaveEditStatus = async (editedOrderStatus: any) => {
    setOrderDetails((prevOrderDetails: any) => {
      if (!prevOrderDetails) return prevOrderDetails;

      return {
        ...prevOrderDetails,
        order_status: {
          ...prevOrderDetails.order_status,
          status: editedOrderStatus.status.status,
        },
      };
    });
  };

  const handleOpenDeleteOrderOrderItemDialog = (orderItem: any) => {
    setOrderOrderItemToDelete(orderItem);
    setOpenDeleteOrderOrderItemDialog(true);
  };

  const handleCloseDeleteOrderOrderItemDialog = () => {
    setOrderOrderItemToDelete(null);
    setOpenDeleteOrderOrderItemDialog(false);
  };

  const handleDeleteOrderStaff = (deletedOrderStaffId: string) => {
    setOrderStaffs((prevOrderStaffs) => {
      const updatedOrderStaffs = prevOrderStaffs.filter(
        (order_staff) => order_staff.order_staff_id !== deletedOrderStaffId,
      );
      return updatedOrderStaffs;
    });
  };

  const handleDeleteOrderOrderItem = (deletedOrderItemId: string) => {
    setOrderItems((prevOrderItems) => {
      const updatedOrderItems = prevOrderItems.filter(
        (order_item) => order_item.order_order_item_id !== deletedOrderItemId,
      );
      return updatedOrderItems;
    });
    handleCloseDeleteOrderOrderItemDialog();
  };

  const handleAddOrderStaff = (newOrderStaff: any) => {
    setOrderStaffs((prevOrderStaffs) => [newOrderStaff, ...prevOrderStaffs]);
  };

  const handleAddOrderOrderItem = (newOrder: any) => {
    const order_order_items = newOrder.order_order_items;
    const order_items = order_order_items.map((order_item: any) => ({
      ...order_item,
      description: order_item.order_item.description,
      price: order_item.order_item.price,
    }));
    setOrderItems((prevOrderItems) => [...prevOrderItems, ...order_items]);
  };

  const handleOpenEditOrderDialog = () => {
    if (orderDetails) setOpenEditOrderDialog(true);
  };

  const handleCloseEditOrderDialog = () => {
    setOpenEditOrderDialog(false);
  };

  const getActionsButton = (row: any) => (
    <IconButton
      aria-label="delete"
      size="large"
      color="primary"
      onClick={() => handleOpenDeleteOrderStaffDialog(row)}
    >
      <DeleteIcon />
    </IconButton>
  );

  const getOrderItemActionsButton = (row: any) => (
    <IconButton
      aria-label="delete"
      size="large"
      color="primary"
      onClick={() => handleOpenDeleteOrderOrderItemDialog(row)}
    >
      <DeleteIcon />
    </IconButton>
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="center" mb={2} alignItems="center">
          <IconButton onClick={handleClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ textAlign: "center", flexGrow: 1 }}>
            Order Info
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Order Details
                  </Typography>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    color="primary"
                    onClick={handleOpenEditOrderDialog}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Typography variant="body1">
                  <strong>Type:</strong> {orderDetails?.type}
                </Typography>
                <Typography variant="body1">
                  <strong>Status:</strong> {orderDetails?.order_status?.status}
                </Typography>

                <Typography variant="body1">
                  <strong>Date:</strong> {orderDetails?.date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6">Customer Details</Typography>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    color="primary"
                    onClick={handleOpenEditDialog}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Typography variant="body1">
                  <strong>Name:</strong> {customerDetails?.first_name}{" "}
                  {customerDetails?.last_name}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {customerDetails?.user_email}
                </Typography>
                <Typography variant="body1">
                  <strong>Phone Number:</strong> {customerDetails?.phone_number}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Staffs Assigned
                  </Typography>
                  <IconButton
                    aria-label="add"
                    size="large"
                    color="primary"
                    onClick={handleOpenAddOrderStaffDialog}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Box>
                <GenericTable
                  columnsConfig={staffColumns}
                  rows={orderStaffs}
                  tableType={"order_item"}
                  getRows={async () => orderStaffs}
                  setRows={setOrderStaffs}
                  getActionsButton={getActionsButton}
                  showPagination={false}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Order Items
                  </Typography>
                  <IconButton
                    aria-label="add"
                    size="large"
                    color="primary"
                    onClick={handleOpenAddOrderOrderItemDialog}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Box>
                <GenericTable
                  columnsConfig={orderItemColumns}
                  rows={orderItems}
                  getRows={async () => orderItems}
                  tableType={"order_item"}
                  setRows={setOrderItems}
                  getActionsButton={getOrderItemActionsButton}
                  showPagination={false}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"order"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"post_production"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"logistics"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"wedding_production"}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <AddOrderStaffDialog
        open={openAddOrderStaffDialog}
        handleClose={handleCloseAddOrderStaffDialog}
        addOrderStaff={handleAddOrderStaff}
      />
      <DeleteFormDialog
        open={openDeleteOrderStaffDialog}
        handleClose={handleCloseDeleteOrderStaffDialog}
        order_staff={orderStaffToDelete}
        onDelete={handleDeleteOrderStaff}
      />
      <DeleteFormDialog
        open={openDeleteOrderOrderItemDialog}
        handleClose={handleCloseDeleteOrderOrderItemDialog}
        order_order_item={orderOrderItemToDelete}
        onDelete={handleDeleteOrderOrderItem}
      />
      <AddOrderOrderItemFormDialog
        open={openAddOrderOrderItemDialog}
        handleClose={handleCloseAddOrderOrderItemDialog}
        order={{ ...orderDetails, order_order_items: [] }}
        addOrderOrderItems={handleAddOrderOrderItem}
      />
      {customerDetails && (
        <EditFormDialog
          open={openEditDialog}
          handleClose={handleCloseEditDialog}
          user={customerDetails}
          onSave={handleSaveEditUser}
        />
      )}
      {orderDetails && (
        <EditOrderFormDialog
          open={openEditOrderDialog}
          handleClose={handleCloseEditOrderDialog}
          row={orderDetails}
          onSave={handleSaveEditOrder}
        />
      )}
    </>
  );
};

export default OrderInfo;
