import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import api from "../api";
import { User, OrderStaffCreate } from "../../api/api";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

interface AddOrderStaffDialogProps {
  open: boolean;
  handleClose: () => void;
  addOrderStaff: (orderStaff: any) => void;
}

const AddOrderStaffDialog: React.FC<AddOrderStaffDialogProps> = ({
  open,
  handleClose,
  addOrderStaff,
}) => {
  const { order_id } = useParams<{ order_id: string }>();
  const [orderStaffs, setOrderStaffs] = React.useState<User[]>([]);
  const [selectedOrderStaffs, setSelectedOrderStaffs] = React.useState<User[]>(
    [],
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [currentOrderStaffCreate, setCurrentOrderStaffCreate] =
    React.useState<OrderStaffCreate | null>(null);

  React.useEffect(() => {
    const fetchOrderStaffs = async () => {
      try {
        const response =
          await api.users.getUsersRoleUsersRoleTargetRoleGet("staff");
        const data = response.data.contents;
        setOrderStaffs(data);
      } catch (error) {
        console.error("Error fetching order staffs:", error);
      }
    };
    fetchOrderStaffs();
  }, []);

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleForceAdd = async () => {
    if (!currentOrderStaffCreate || !order_id) return;

    try {
      const post_response = await api.orderStaffs.postOrderStaffOrderStaffsPost(
        currentOrderStaffCreate,
        true,
      );
      const newOrderStaff: any = post_response.data;

      const data = {
        name: `${newOrderStaff.user.first_name} ${newOrderStaff.user.last_name}`,
        profession: newOrderStaff.user.profession,
      };

      addOrderStaff(data);
      handleClose();
    } catch (error) {
      console.error("Error forcing order staff addition:", error);
    } finally {
      handleConfirmDialogClose();
    }
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!order_id) {
      console.error("Order ID is undefined.");
      return;
    }

    try {
      const orderResponse = await api.orders.getOrderOrdersOrderIdGet(order_id);
      const orderDate = orderResponse.data.date;

      for (const selectedStaff of selectedOrderStaffs) {
        const orderStaffCreate: OrderStaffCreate = {
          date: null,
          order_id: order_id,
          user_id: selectedStaff.user_id,
        };

        try {
          const post_response =
            await api.orderStaffs.postOrderStaffOrderStaffsPost(
              orderStaffCreate,
            );
          const newOrderStaff: any = post_response.data;

          const data = {
            name: `${newOrderStaff.user.first_name} ${newOrderStaff.user.last_name}`,
            profession: newOrderStaff.user.profession,
          };

          addOrderStaff(data);
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 403) {
            setCurrentOrderStaffCreate(orderStaffCreate);
            setConfirmDialogOpen(true);
          } else {
            console.error("Error adding order staff:", error);
          }
        }
      }

      setSelectedOrderStaffs([]);
      handleClose();
    } catch (error) {
      console.error("Error adding order staff:", error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmitForm,
        }}
      >
        <DialogTitle>Add Order Staffs</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the order staffs to add:
          </DialogContentText>

          <Autocomplete
            multiple
            id="order-staffs-select"
            options={orderStaffs}
            getOptionLabel={(option) => {
              const profession =
                option.extras?.profession ?? "Unknown Profession";
              const firstName = option.first_name ?? "";
              const lastName = option.last_name ?? "";
              return `${profession} - ${firstName} ${lastName}`;
            }}
            value={selectedOrderStaffs}
            onChange={(event, newValue) => {
              setSelectedOrderStaffs(newValue);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={`${option.extras?.profession ?? "Unknown Profession"} - ${option.first_name ?? ""} ${option.last_name ?? ""}`}
                  {...getTagProps({ index })}
                  key={option.user_id}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Order Staffs"
                placeholder="Select order staffs"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            type="submit"
            sx={{
              backgroundColor: "#2196f3",
              color: "white",
              "&:hover": { backgroundColor: "#64b5f6" },
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Staff Unavailable</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Staff is unavailable. Do you want to force the assignment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>No</Button>
          <Button
            onClick={handleForceAdd}
            sx={{
              backgroundColor: "#2196f3",
              color: "white",
              "&:hover": { backgroundColor: "#64b5f6" },
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddOrderStaffDialog;
