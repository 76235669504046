import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

interface TableItemProps {
  row: any;
  columns: any;
  getActionsButton: (row: any) => JSX.Element;
  handleRowClickInternal?: (row: any) => void; 
}

const TableItem: React.FC<TableItemProps> = ({
  row,
  columns,
  getActionsButton,
  handleRowClickInternal,
}) => {
  const renderCellContent = (columnId: string, data: any) => {
    if (columnId === "actions") {
      return getActionsButton(row);
    } else {
      const getColumnValue = (obj: any, keys: string[]) => {
        return keys.reduce(
          (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
          obj,
        );
      };

      const keys = columnId.split(".");
      const columnValue = getColumnValue(data, keys);

      return columnValue !== null && columnValue !== undefined
        ? columnValue.toString()
        : "";
    }
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}
        onClick={() => handleRowClickInternal?.(row)}>
        {columns.map((column: any) => (
          <TableCell key={column.id} align={column.align}>
            {renderCellContent(column.id, row)}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default TableItem;
