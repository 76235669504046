import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TableItem from "./TableItem/TableItem";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | undefined;
  format?: (value: number) => string;
}

interface TableProps {
  columnsConfig: any[];
  tableType:
    | "client"
    | "staff"
    | "admin"
    | "order_item"
    | "order"
    | "notification_rule";
  rows: any[];
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  getActionsButton: (row: any) => JSX.Element;
  getRows: () => Promise<any[]>;
  showPagination?: boolean;
  onRowClick?: (row: any) => void;
}

const GenericTable: React.FC<TableProps> = ({
  columnsConfig,
  tableType,
  rows,
  setRows,
  getActionsButton,
  getRows,
  showPagination = true,
  onRowClick,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRows();

        data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setRows(data);
      } catch (error) {
        console.error(`Error fetching table data:`, error);
      }
    };

    fetchData();
  }, [tableType, setRows]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClickInternal = (row: any) => {
    if (onRowClick) {
      onRowClick(row); 
    }
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Paper sx={{ width: "100%", maxWidth: "1200px", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsConfig.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.id === "actions" ? "center" : undefined}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableItem
                    key={row.id}
                    row={row}
                    columns={columnsConfig}
                    getActionsButton={getActionsButton}
                    handleRowClickInternal={handleRowClickInternal}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default GenericTable;
