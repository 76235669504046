import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { User, Order, Question, QuestionPatch } from "../../api/api";
import TableItem from "./TableItem/TableItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableItem from "./TableItem/DraggableItem";
import axios, { AxiosResponse } from "axios";
import api from "../api";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | undefined;
  format?: (value: number) => string;
}

interface TableProps {
  columnsConfig: any[];
  rows: any[];
  getRows: () => Promise<any[]>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  handleEditQuestion: (question: any) => void;
  isReordering: boolean;
  getActionsButton: (row: any) => JSX.Element;
}

const DragTable: React.FC<TableProps> = ({
  columnsConfig,
  rows,
  getRows,
  setRows,
  handleEditQuestion,
  isReordering,
  getActionsButton,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRows();
        data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setRows(data);
      } catch (error) {
        console.error(`Error fetching question data:`, error);
      }
    };

    fetchData();
  }, ["question", setRows]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onDragEndWrapper = (result: any) => {
    if (!result.destination) return;

    const movedRow = rows[result.source.index];
    onDragEnd(result, movedRow);
  };

  const onDragEnd = async (result: any, movedRow: Question) => {
    if (!result.destination) return;

    const newRows = Array.from(rows);
    newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, movedRow);
    setRows(newRows);

    const questionPatch: QuestionPatch = {
      ...movedRow,
      rank: result.destination.index,
    };

    try {
      const response =
        await api.questions.patchQuestionQuestionsQuestionIdPatch(
          movedRow.question_id,
          questionPatch,
        );
      handleEditQuestion(response.data);
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Paper sx={{ width: "100%", maxWidth: "1200px", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsConfig.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.id == "actions" ? "center" : undefined}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isReordering ? (
              <DragDropContext onDragEnd={onDragEndWrapper}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row, index) => (
                          <DraggableItem
                            key={row.question_id.toString()}
                            row={row}
                            columns={columnsConfig}
                            index={index}
                            provided={provided}
                            getActionsButton={getActionsButton}
                          />
                        ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableItem
                      key={row.question_id.toString()}
                      row={row}
                      columns={columnsConfig}
                      getActionsButton={getActionsButton}
                    />
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{ display: "flex", justifyContent: "center" }}
        />
      </Paper>
    </Box>
  );
};

export default DragTable;
