import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Question, Answer } from "../../api/api";

interface OutlinedCardProps {
  question: Question;
  answer: Answer;
}

const OutlinedCard: React.FC<OutlinedCardProps> = ({ question, answer }) => {
  const attachments = answer.attachments || [];

  const isPDF = (url: string) => url.toLowerCase().endsWith(".pdf");

  const imageAttachments = attachments.filter((url) => !isPDF(url));
  const pdfAttachments = attachments.filter((url) => isPDF(url));

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Card variant="outlined" sx={{ width: "100%", mx: "auto" }}>
        <CardContent sx={{ padding: 3 }}>
          <Typography variant="body1">
            {question.rank}. {question.text}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            {answer.text}
          </Typography>

          {pdfAttachments.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {pdfAttachments.map((url, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 2 }}
                >
                  <Typography
                    variant="body2"
                    component="a"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "text.primary",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PictureAsPdfIcon sx={{ mr: 1 }} />
                    {url}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {imageAttachments.length > 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <ImageList
                sx={{ width: "67%", height: 250 }}
                cols={3}
                rowHeight={250}
              >
                {imageAttachments.map((url, index) => (
                  <ImageListItem key={index}>
                    <Zoom zoomMargin={40}>
                      <img
                        srcSet={`${url}?w=500&fit=contain&auto=format&dpr=2 2x`}
                        src={`${url}?w=500&fit=contain&auto=format`}
                        alt={`attachment-${index}`}
                        loading="lazy"
                        style={{
                          marginBottom: 2,
                          width: 250,
                          height: 250,
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    </Zoom>
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default OutlinedCard;
