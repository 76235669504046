import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../auth_context";

interface NavItemProps {
  to: string;
  icon: React.ReactNode;
  primary: string;
}

const SidebarItem: React.FC<NavItemProps> = ({ to, icon, primary }) => {
  const { isLoggedIn, logout } = useAuth();
  const handleClick = () => {
    if (to === "/login" && isLoggedIn) {
      logout();
    }
  };
  return (
    <ListItem button component={RouterLink} to={to} onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default SidebarItem;
