import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { config } from "./api";

interface DecodedJwt {
  role: string;
  user_id: string;
  exp: number;
}

interface AuthContextProps {
  token: string | null;
  role: string | null;
  login: (token: string) => void;
  logout: () => void;
  isLoggedIn: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const isLoggedIn = !!token;

  useEffect(() => {
    const access_token = window.localStorage.getItem("access_token");
    if (access_token) {
      const decoded_jwt = jwtDecode<DecodedJwt>(access_token);
      setToken(access_token);
      setRole(decoded_jwt.role);
      config.accessToken = access_token;
      config.role = decoded_jwt.role;
    }
  }, []);

  const login = (accessToken: string) => {
    window.localStorage.setItem("access_token", accessToken);
    const decoded_jwt = jwtDecode<DecodedJwt>(accessToken);
    setToken(accessToken);
    setRole(decoded_jwt.role);
    config.accessToken = accessToken;
    config.role = decoded_jwt.role;
  };

  const logout = () => {
    window.localStorage.removeItem("access_token");
    setToken(null);
    setRole(null);
    config.accessToken = undefined;
    config.role = undefined;
  };

  return (
    <AuthContext.Provider value={{ token, role, login, logout, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
