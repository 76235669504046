import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AxiosResponse } from "axios";
import {
  Order,
  OrderPatch,
  Status,
  OrderType,
  OrderOrderItem,
  OrderStatusPatch,
} from "../../api/api";
import api from "../api";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import moment from "moment/moment";

interface EditOrderFormDialogProps {
  open: boolean;
  handleClose: () => void;
  row: any;
  onSave: (order: any) => void;
}

const EditOrderFormDialog: React.FC<EditOrderFormDialogProps> = ({
  open,
  handleClose,
  row,
  onSave,
}) => {
  const [orderPatch, setOrderPatch] = React.useState<OrderPatch>({
    type: row.type,
    date: row.date,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOrderPatch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setOrderPatch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Dayjs | null) => {
    setOrderPatch((prevState) => ({
      ...prevState,
      date: date ? date.toISOString() : "",
    }));
  };
  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (orderPatch.date) {
      orderPatch.date = dayjs(orderPatch.date).toISOString();
    }

    try {
      const response: AxiosResponse =
        await api.orders.patchOrderOrderIdOrdersOrderIdPatch(
          row.order_id,
          orderPatch,
        );

      const orderDate = moment(response.data.date).format(
        "YYYY/MM/DD HH:mm:ss",
      );

      const data = {
        ...response.data,
        name:
          response.data.user.first_name + " " + response.data.user.last_name,
        date: orderDate,
        order_order_items: row.order_order_items,
      };

      onSave(data);

      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Edit Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please update the necessary information:
        </DialogContentText>

        <FormControl fullWidth margin="dense">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            name="type"
            value={orderPatch.type || ""}
            onChange={handleSelectChange}
            label="Type"
          >
            {Object.values(OrderType).map((type) => (
              <MenuItem key={type} value={type}>
                {type.replace(/_/g, " ").toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box mt={1}>
            <DatePicker
              label="Order Date"
              value={orderPatch.date ? dayjs(orderPatch.date) : null}
              onChange={handleDateChange}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderFormDialog;
