import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import api from "../components/api";
import { Question, AnswerCreate, AnswerRequest } from "../api/api";

const FileItem: React.FC<{ fileName: string; onDelete: () => void }> = ({
  fileName,
  onDelete,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      mb: 1,
      justifyContent: "space-between",
      border: "1px solid #ddd",
      borderRadius: "4px",
      p: 1,
    }}
  >
    <Typography variant="body2">{fileName}</Typography>
    <IconButton onClick={onDelete} size="small">
      <ClearIcon />
    </IconButton>
  </Box>
);

const MAX_FILE_SIZE_MB = 10;

const Form: React.FC<{}> = () => {
  const { target_type, order_id } = useParams<{
    target_type: string;
    order_id: string;
  }>();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});
  const [fileNames, setFileNames] = useState<{
    [key: string]: string[] | null | undefined;
  }>({});
  const [files, setFiles] = useState<{ [key: string]: File[] }>({});
  const [newFileUrls, setNewFileUrls] = useState<{ [key: string]: string[] }>(
    {},
  );
  const [error, setError] = useState<string | null>(null);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response =
          await api.questions.getQuestionsQuestionsTypeTargetTypeGet(
            target_type || "default_target_type",
          );
        const sortedQuestions = response.data.contents.sort(
          (a: Question, b: Question) => a.rank - b.rank,
        );
        setQuestions(sortedQuestions);
      } catch (err) {
        console.error("Erreur lors de la récupération des questions :", err);
      }
    };

    fetchQuestions();

    api.answers
      .getAnswerOrderIdOrdersOrderIdAnswersGet(order_id || "")
      .then((res) => {
        if (res.data.contents.length > 0) setReset(true);
        const answers = res.data.contents.reduce(
          (acc: { [key: string]: string }, answer) => {
            acc[answer.question_id] = answer.text;
            return acc;
          },
          {},
        );
        setAnswers(answers);
        const fileNames = res.data.contents.reduce(
          (acc: { [key: string]: string[] | null | undefined }, answer) => {
            acc[answer.question_id] = answer.attachments?.map(
              (attachment) => attachment.split("/").pop() || "",
            );
            return acc;
          },
          {},
        );
        setFileNames(fileNames);
        const getFileUrls = res.data.contents.reduce(
          (acc: { [key: string]: string[] }, answer) => {
            acc[answer.question_id] = answer.attachments || [];
            return acc;
          },
          {},
        );
        setNewFileUrls(getFileUrls);
      });
  }, [target_type]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionId: string,
  ) => {
    const newValue = event.target.value;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: newValue,
    }));
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionId: string,
  ) => {
    const filesArray = event.target.files ? Array.from(event.target.files) : [];

    const validFiles = filesArray.filter(
      (file) => file.size <= MAX_FILE_SIZE_MB * 1024 * 1024,
    );
    const invalidFiles = filesArray.filter(
      (file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024,
    );

    if (invalidFiles.length > 0) {
      setError(`Some files exceed the maximum size of ${MAX_FILE_SIZE_MB} MB.`);
    } else {
      setError(null);
    }

    const newFileNames = validFiles.map((file) => file.name);

    setFileNames((prevFileNames) => ({
      ...prevFileNames,
      [questionId]: [...(prevFileNames[questionId] || []), ...newFileNames],
    }));

    setFiles((prevFiles) => ({
      ...prevFiles,
      [questionId]: [...(prevFiles[questionId] || []), ...validFiles],
    }));
  };

  const handleFileDelete = (questionId: string, fileName: string) => {
    setFileNames((prevFileNames) => ({
      ...prevFileNames,
      [questionId]: (prevFileNames[questionId] || []).filter(
        (name) => name !== fileName,
      ),
    }));

    setFiles((prevFiles) => ({
      ...prevFiles,
      [questionId]: (prevFiles[questionId] || []).filter(
        (file) => file.name !== fileName,
      ),
    }));
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (error) {
      alert(error);
      return;
    }

    if (reset) {
      api.answers.deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(
        order_id || "",
      );
    }

    const fileUploadPromises = Object.entries(files).map(
      async ([questionId, filesArray]) => {
        try {
          const response = await api.files.uploadFilesDirPost(
            "form_uploads",
            filesArray,
          );
          if (response.data.success) {
            return { questionId, fileUrls: response.data.file_urls };
          } else {
            throw new Error("File upload failed");
          }
        } catch (error) {
          console.error("Error uploading files:", error);
          return { questionId, fileUrls: [] };
        }
      },
    );

    try {
      const fileResponses = await Promise.all(fileUploadPromises);

      const updatedFileUrls: { [key: string]: string[] } = {};
      fileResponses.forEach(({ questionId, fileUrls }) => {
        updatedFileUrls[questionId] = fileUrls;
      });
      for (const questionId in newFileUrls) {
        if (!updatedFileUrls[questionId]) {
          updatedFileUrls[questionId] = newFileUrls[questionId];
        } else {
          updatedFileUrls[questionId] = [
            ...updatedFileUrls[questionId],
            ...newFileUrls[questionId],
          ];
        }
      }
      setNewFileUrls(updatedFileUrls);

      const formattedAnswers: AnswerCreate[] = questions.map((question) => ({
        text: answers[question.question_id] || "",
        attachments: updatedFileUrls[question.question_id] || [],
        order_id: order_id || "default_order_id",
        question_id: question.question_id,
      }));

      const answerRequest: AnswerRequest = {
        answers: formattedAnswers,
      };

      await api.answers.postAnswersAnswersBulkPost(answerRequest);

      navigate("/orders/form/thanks");
    } catch (err) {
      console.error(
        "Erreur lors de l'envoi des réponses ou des fichiers :",
        err,
      );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmitForm}
      sx={{
        width: "100%",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h5" mb={1}>
        Form
      </Typography>
      <Typography mb={1}>Please fill out the following questions:</Typography>
      {questions.map((question) => (
        <Box key={question.question_id} sx={{ width: "100%", mb: 1 }}>
          <Card variant="outlined" sx={{ width: "100%", mx: "auto" }}>
            <CardContent sx={{ padding: 3 }}>
              <Typography variant="body1">
                {question.rank}. {question.text}
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Your response"
                value={answers[question.question_id] || ""}
                onChange={(event) =>
                  handleInputChange(
                    event as React.ChangeEvent<HTMLInputElement>,
                    question.question_id,
                  )
                }
              />

              <Button
                component="label"
                variant="contained"
                sx={{ mt: 2, mb: 2, display: "flex", alignItems: "center" }}
              >
                <CloudUploadIcon />
                <input
                  type="file"
                  onChange={(event) =>
                    handleFileChange(event, question.question_id)
                  }
                  style={{
                    clip: "rect(0, 0, 0, 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  multiple
                />
              </Button>

              <Box sx={{ mt: 1 }}>
                {(fileNames[question.question_id] ?? []).length > 0 && (
                  <>
                    {fileNames[question.question_id]?.map((fileName) => (
                      <FileItem
                        key={fileName}
                        fileName={fileName}
                        onDelete={() =>
                          handleFileDelete(question.question_id, fileName)
                        }
                      />
                    ))}
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      ))}
      {error && (
        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
