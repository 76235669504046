import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AxiosResponse } from "axios";
import api from "../api";
import {
  NotificationRule,
  NotificationRuleCreate,
  QuestionCreate,
  Status,
  UserProfession,
  UserRole,
} from "../../api/api";
import { Autocomplete, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";

interface AddNotificationRuleFormDialogProps {
  open: boolean;
  handleClose: () => void;
  setNotificationRules: React.Dispatch<
    React.SetStateAction<NotificationRule[]>
  >;
}

const AddNotificationRuleFormDialog: React.FC<
  AddNotificationRuleFormDialogProps
> = ({ open, handleClose, setNotificationRules }) => {
  const [notificationRule, setNotificationRule] =
    React.useState<NotificationRuleCreate>({
      user_role: "admin",
      user_professions: [],
      status_id: "",
      inform_day: 0,
    });
  const [status, setStatus] = React.useState<Status[]>([]);

  useEffect(() => {
    api.status.getStatusStatusGet().then((res) => {
      setStatus(res.data.contents);
    });
  }, []);

  const handleAddNotificationRule = () => {
    console.log(notificationRule);
    api.notificationRules
      .postNotificationRulesNotificationRulesPost(notificationRule)
      .then((res) => {
        setNotificationRules((prev) => [...prev, res.data]);
        handleClose();
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Notification Rule</DialogTitle>
      <DialogContent>
        <label htmlFor="user-professions">User Role</label>
        <Select
          margin="dense"
          id="user-role"
          name="user-role"
          label="User Role"
          fullWidth
          variant="standard"
          required
          value={notificationRule?.user_role}
          onChange={(e) =>
            setNotificationRule({
              ...notificationRule,
              user_role: e.target.value as UserRole,
            })
          }
        >
          {Object.values(UserRole).map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>

        <label htmlFor="user-professions">User Professions</label>
        <Autocomplete
          multiple
          id="user-professions"
          options={Object.values(UserProfession)}
          getOptionLabel={(option) => option}
          value={notificationRule.user_professions}
          onChange={(event, newValue) => {
            setNotificationRule({
              ...notificationRule,
              user_professions: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="User Professions"
              placeholder="Select professions"
              fullWidth
            />
          )}
        />

        <label htmlFor="status">Status</label>
        <Select
          margin="dense"
          id="status"
          name="status"
          label="Status"
          fullWidth
          variant="standard"
          required
          value={notificationRule?.status_id}
          onChange={(e) =>
            setNotificationRule({
              ...notificationRule,
              status_id: e.target.value as string,
            })
          }
        >
          {status.map((status) => (
            <MenuItem key={status.status_id} value={status.status_id}>
              {status.status}
            </MenuItem>
          ))}
        </Select>

        <TextField
          margin="dense"
          id="inform-day"
          name="inform-day"
          label="Inform Day (Days Before)"
          type="number"
          fullWidth
          variant="standard"
          required
          value={notificationRule?.inform_day}
          onChange={(e) =>
            setNotificationRule({
              ...notificationRule,
              inform_day: parseInt(e.target.value),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
          onClick={handleAddNotificationRule}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNotificationRuleFormDialog;
