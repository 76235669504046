import React from "react";
import { Box, Typography } from "@mui/material";

const ThankYouPage: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      p: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Typography variant="h4" mb={2}>
      Thank You!
    </Typography>
    <Typography variant="body1">Thank you for filling out the form</Typography>
  </Box>
);

export default ThankYouPage;
