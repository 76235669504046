import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Management from "./pages/Management";
import Order from "./pages/Order";
import Question from "./pages/Question";
import OrderInfo from "./pages/OrderInfo";
import Form from "./pages/Form";
import Thanks from "./pages/Thanks";
import { AuthProvider } from "./components/auth_context";
import OrderAnswer from "./pages/OrderAnswer"; // Importer le AuthProvider
import NotificationRules from "./pages/NotificationRules";
import StatusCategories from "./pages/StatusCategories";

ReactDOM.render(
  <AuthProvider>
    {" "}
    {/* Envelopper votre application avec AuthProvider */}
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin-management"
            element={<Management userType="admin" />}
          />
          <Route
            path="/staff-management"
            element={<Management userType="staff" />}
          />
          <Route
            path="/client-management"
            element={<Management userType="client" />}
          />
          <Route path="/order" element={<Order />} />
          <Route path="/order/:user_id" element={<Order />} />
          <Route path="/question" element={<Question />} />
          <Route path="/order_info/:order_id" element={<OrderInfo />} />
          <Route path="/order_answer/:order_id" element={<OrderAnswer />} />
          <Route
            path="/orders/:order_id/form/:target_type"
            element={<Form />}
          />
          <Route path="/orders/form/thanks" element={<Thanks />} />
          <Route path="/notification_rules" element={<NotificationRules />} />
          <Route path="/status_categories" element={<StatusCategories />} />
        </Route>
      </Routes>
    </Router>
  </AuthProvider>,
  document.getElementById("root"),
);
