import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { OrderOrderItem } from "../../api/api";
import CollapsibleTableItem from "./TableItem/CollapsibleTableItem";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | undefined;
  format?: (value: number) => string;
}

interface TableProps {
  columnsConfig: any[];
  detailColumns?: any[];
  tableType: "order";
  rows: any[];
  getRows: () => Promise<any[]>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  handleOpenDeleteOrderOrderItemDialog: (
    row: any,
    orderOrderItem: OrderOrderItem,
  ) => void;
  handleOpenAddOrderOrderItemDialog: (row: any) => void;
  getActionsButton: (row: any) => JSX.Element;
}

const GenericTable: React.FC<TableProps> = ({
  columnsConfig,
  detailColumns,
  tableType,
  rows,
  getRows,
  setRows,
  handleOpenDeleteOrderOrderItemDialog,
  handleOpenAddOrderOrderItemDialog,
  getActionsButton,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRows();

        data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setRows(data);
      } catch (error) {
        console.error(`Error fetching ${tableType} data:`, error);
      }
    };

    fetchData();
  }, [tableType, setRows]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Paper sx={{ width: "100%", maxWidth: "1200px", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsConfig.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.id == "actions" ? "center" : undefined}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <CollapsibleTableItem
                    key={row.id}
                    row={row}
                    columns={columnsConfig}
                    detailColumns={detailColumns}
                    handleOpenAddOrderOrderItemDialog={
                      handleOpenAddOrderOrderItemDialog
                    }
                    handleOpenDeleteOrderOrderItemDialog={
                      handleOpenDeleteOrderOrderItemDialog
                    }
                    getActionsButton={getActionsButton}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{ display: "flex", justifyContent: "center" }}
        />
      </Paper>
    </Box>
  );
};

export default GenericTable;
