import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import api from "../api";
import {
  OrderItem,
  OrderOrderItem,
  OrderOrderItemCreate,
  Order,
} from "../../api/api";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";

interface AddOrderOrderItemFormDialogProps {
  open: boolean;
  order: any;
  handleClose: () => void;
  addOrderOrderItems: (order: Order) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  selectedItems: readonly string[],
  theme: any,
) => {
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const AddOrderOrderItemFormDialog: React.FC<
  AddOrderOrderItemFormDialogProps
> = ({ open, handleClose, addOrderOrderItems, order }) => {
  const theme = useTheme();

  const [orderItems, setOrderItems] = React.useState<OrderItem[]>([]);
  const [selectedOrderItems, setSelectedOrderItems] = React.useState<string[]>(
    [],
  );

  React.useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const response = await api.orderItems.getOrderItemsOrderItemsGet();
        const data = response.data.contents;
        setOrderItems(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des order items :",
          error,
        );
      }
    };
    fetchOrderItems();
  }, []);

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderItems(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      let allOrderOrderItems: OrderOrderItem[] = [];

      for (const orderItemId of selectedOrderItems) {
        const orderOrderItemCreate: OrderOrderItemCreate = {
          order_id: order.order_id,
          order_item_id: orderItemId,
        };

        const orderOrderItemResponse =
          await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(
            orderOrderItemCreate,
          );
        const orderOrderItem = orderOrderItemResponse.data;

        allOrderOrderItems.push(orderOrderItem);
      }

      const data = {
        ...order,
        order_order_items: [...allOrderOrderItems, ...order.order_order_items],
      };
      addOrderOrderItems(data);
      setSelectedOrderItems([]);
      handleClose();
    } catch (error) {
      console.error("Error adding order items:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Add Order Items</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the order items to add:
        </DialogContentText>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-items-select-label">Order Items</InputLabel>
          <Select
            labelId="order-items-select-label"
            id="order-items-select"
            multiple
            value={selectedOrderItems}
            onChange={handleMultiSelectChange}
            input={
              <OutlinedInput id="select-multiple-chip" label="Order Items" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      orderItems.find((item) => item.order_item_id === value)
                        ?.description || value
                    }
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {orderItems.map((item) => (
              <MenuItem
                key={item.order_item_id}
                value={item.order_item_id}
                style={getStyles(item.description, selectedOrderItems, theme)}
              >
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderOrderItemFormDialog;
