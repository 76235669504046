import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../api";
import { OrderStaff, User } from "../../api";
import { config } from "../../components/api";
import moment from 'moment';


interface AlignItemsListProps {
  user: User;
}

export default function AlignItemsList({ user }: AlignItemsListProps) {
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState<any[]>([]); 
  const [clientName, setClientName] = React.useState('');

  React.useEffect(() => {
    const fetchOrders = async () => {
      try {
      let response;
      if (config.role === "staff") {
        response = await api.orderStaffs.getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(user.user_id);
      } else if (config.role && ["admin", "manager", "client"].includes(config.role)) {
        response = await api.orders.getOrdersOrdersGet();
      }

      if (response && response.data && response.data.contents) {
        const ordersData = response.data.contents.map((order: any) => {
          const orderDate = moment(order.date).format("YYYY/MM/DD HH:mm:ss");
          const status = order.order_status?.status || "Unknown";

          const orderItems = order.order_order_items?.map((item: any) => ({
            ...item,
            description: item.description || "No description",
            price: item.price || 0,
            order_item_id: item.order_item_id,
          })) || [];

          return {
            ...order,
            date: orderDate,
            name: `${order.user.first_name} ${order.user.last_name}`, 
            type: order.type,
            status: status, 
            order_order_items: orderItems,
          };
        });
        setOrders(ordersData);

        if (ordersData.length > 0) {
          const { first_name, last_name } = ordersData[0].user;
          setClientName(`${first_name} ${last_name}`);
        }
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

    fetchOrders();
  }, [config.role, user.user_id]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <List sx={{ width: "100%", height: "100%", bgcolor: "background.paper" }}>
      {orders.map((order, index) => (
        <React.Fragment key={order.order_id}>
          <ListItem alignItems="flex-start">
            <Box sx={{ minWidth: 120, textAlign: "center" }}>
              <Typography variant="h6" component="span" color="text.primary">
                {order.order && order.order.date
                  ? new Date(order.order.date).toLocaleDateString()
                  : new Date(order.date).toLocaleDateString()}
              </Typography>
            </Box>
            <ListItemText
              primary={
                config.role === "staff"
                  ?(order.order && Object.keys(order.order).length > 0
                    ? order.order.type
                    : "Unavailable")
                  : (order && Object.keys(order).length > 0 
                    ? order.type 
                    : "Unavailable")
              }
              secondary={
                order.order && Object.keys(order.order).length > 0 ? (
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {order.order.status}
                  </Typography>
                ) : null
              }
            />
          </ListItem>
          {index < orders.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
