import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Question } from "../../../api/api";
import EditQuestionFormDialog from "../../FormDialog/EditQuestionFormDialog";
import DeleteFormDialog from "../../FormDialog/DeleteFormDialog";
import { Draggable } from "react-beautiful-dnd";

interface DraggableItemProps {
  row: Question;
  columns: any;
  index?: number;
  provided?: any;
  getActionsButton: (row: any) => JSX.Element;
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  row,
  columns,
  index,
  provided,
  getActionsButton,
}) => {
  const renderCellContent = (columnId: string, data: any) => {
    if (columnId === "actions") {
      getActionsButton(row);
    } else {
      const getColumnValue = (obj: any, keys: string[]) => {
        return keys.reduce(
          (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
          obj,
        );
      };

      const keys = columnId.split(".");
      const columnValue = getColumnValue(data, keys);

      return columnValue !== null && columnValue !== undefined
        ? columnValue.toString()
        : "";
    }
  };

  return (
    <>
      <Draggable draggableId={row.question_id.toString()} index={index ?? 0}>
        {(provided, snapshot) => (
          <>
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {columns.map((column: any) => (
                <TableCell key={column.id} align={column.align}>
                  {renderCellContent(column.id, row)}
                </TableCell>
              ))}
            </TableRow>
          </>
        )}
      </Draggable>
    </>
  );
};

export default DraggableItem;
