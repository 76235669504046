import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import api from "../api";

interface SendFormDialogProps {
  open: boolean;
  handleClose: () => void;
  order_id: string;
  onLinkGenerated: (link: string) => void;
}

const SendFormDialog: React.FC<SendFormDialogProps> = ({
  open,
  handleClose,
  order_id,
  onLinkGenerated,
}) => {
  const [types, setTypes] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string | null>(null);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response =
          await api.questions.getQuestionTypesQuestionsTypesGet();
        setTypes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des types :", error);
      }
    };

    fetchTypes();
  }, []);

  const handleGenerateLink = () => {
    if (selectedType && order_id) {
      const generatedLink = `/orders/${order_id}/form/${selectedType}`;
      onLinkGenerated(generatedLink);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        sx: { minWidth: "300px" },
      }}
    >
      <DialogTitle>Generate Form Link</DialogTitle>
      <DialogContent>
        <Autocomplete
          freeSolo
          value={selectedType || ""}
          onChange={(event, value) => setSelectedType(value)}
          options={types}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Type"
              fullWidth
              variant="standard"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleGenerateLink} color="primary">
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendFormDialog;
