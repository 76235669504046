import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api, { config } from "../components/api";
import OutlinedCard from "../components/Card/OutlinedCard";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendFormDialog from "../components/FormDialog/SendFormDialog";
import Link from "@mui/material/Link";
import LinkIcon from "@mui/icons-material/Link";

const OrderAnswer: React.FC = () => {
  const { order_id } = useParams<{ order_id?: string }>();
  const [answers, setAnswers] = useState<any[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [generatedLink, setGeneratedLink] = useState<string | null>(null);

  useEffect(() => {
    if (order_id) {
      const fetchAnswers = async () => {
        try {
          const response =
            await api.answers.getAnswerOrderIdOrdersOrderIdAnswersGet(order_id);
          const sortedAnswers = response.data.contents.sort(
            (a: any, b: any) => a.question.rank - b.question.rank,
          );
          setAnswers(sortedAnswers);
        } catch (err) {
          console.error(err);
        }
      };

      fetchAnswers();
    }
  }, [order_id]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/order/`);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleLinkGenerated = (link: string) => {
    setGeneratedLink(link);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "1rem" }}
        >
          <IconButton aria-label="info" onClick={handleClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">Answers</Typography>

          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            {(config.role === "admin" || config.role === "manager") && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                  disabled={answers.length > 0}
                >
                  Get link
                </Button>
              </>
            )}
          </Box>
        </Box>
        {(config.role === "admin" || config.role === "manager") && (
          <SendFormDialog
            open={dialogOpen}
            handleClose={handleCloseDialog}
            order_id={order_id || ""}
            onLinkGenerated={handleLinkGenerated}
          />
        )}

        {generatedLink && (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              Generated Link
              <Link
                href={generatedLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: "flex", alignItems: "center", ml: 1 }}
              >
                <LinkIcon sx={{ ml: 0.5 }} />
              </Link>
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "300px" }}
        >
          {answers.length > 0 ? (
            answers.map((answer, index) => (
              <OutlinedCard
                key={index}
                question={answer.question}
                answer={answer}
              />
            ))
          ) : (
            <Typography variant="body1">No answers available</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OrderAnswer;
